<template>

  <transition :name="transitionName">
    <div>

      <div
        class="page-component-up"
        @click="backToTop"
        v-show="visible"
      >
        <van-icon
          name="back-top"
          size="0.6rem"
        />

      </div>
      <div
        class="page-component-up"
        style="margin-top:30px"
        v-if="downShow"
        @click="toBottom"
      >
        <van-icon
          name="down"
          size="0.6rem"
        />
      </div>
    </div>

  </transition>
</template>

<script>
export default {
  name: "BackToTop",
  props: {
    transitionName: {
      type: String,
      default: "van-slide-right",
    },

    visibilityHeight: {
      // 纵向滑动多远距离出现滚动条
      type: Number,
      default: 50,
    },
    backPosition: {
      // 返回顶部时，滚动到哪里（距离顶部的距离）
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      interval: null,
      downShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    handleScroll() {
      this.visible = window.pageYOffset > this.visibilityHeight;
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollHeight = document.documentElement.scrollHeight;

      let rollheight = window.pageYOffset + clientHeight;

      this.downShow = rollheight < scrollHeight ? true : false;
    },
    backToTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;

      // let distanceY = window.pageYOffset;

      // let i = 0;
      // this.interval = setInterval(() => {
      //   let next = Math.floor(
      //     this.easeInOutQuad(10 * i, distanceY, -distanceY, 500)
      //   );
      //   if (next <= this.backPosition) {
      //     window.scrollTo(0, this.backPosition);
      //     clearInterval(this.interval);
      //   } else {
      //     window.scrollTo(0, next);
      //   }
      //   i++;
      // }, 17);
    },
    easeInOutQuad(t, b, c, d) {
      // 判断当前时间是否总在总时间的一半以内，是的话执行缓入函数，否则的话执行缓出函数
      if ((t /= d / 2) < 1) {
        return (c / 2) * t * t + b;
      } else {
        // 将总长度设置为一半，并且时间从当前开始递减，对图像进行垂直向上平移
        return (-c / 2) * (--t * (t - 2) - 1) + b;
      }
    },
    toBottom(i) {
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      let rollheight = scrollHeight - clientHeight; //超出窗口上界的值就是底部的scrolTop的值
      document.documentElement.scrollTop = document.body.scrollTop = rollheight;
      // document.documentElement.scrollTop += 100;
      // if (document.documentElement.scrollTop + 1 <= rollheight) {
      //   //之所以+1，可以打印这两个值的日志就知道了，下面+1也是同理
      //   var c = setTimeout(() => this.toBottom(i), 10); //调用setTimeout是为了“回到底部”这过程不是一瞬间
      // } else {
      //   clearTimeout(c);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-component-up {
  position: fixed;
  top: 80%;
  right: 2px;
  width: 40px;
  height: 40px;
  margin: -50px 0 0 -50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
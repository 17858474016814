export default {
  /**
   * 用户类型：
   * //userLevel: 0 普通用户，1 1级代理，2 2级代理，3 3级代理，4 操作员
   */
  USER_LEVEL0: 0,
  USER_LEVEL1: 1,
  USER_LEVEL2: 2,
  USER_LEVEL3: 3,
  USER_LEVEL4: 4
}